;(function () {
  'use strict'

  angular
    .module('ottomatikStoreManager.statistics')
    .controller('StatisticsArticlesController', StatisticsArticlesController)

  function StatisticsArticlesController(
    $filter,
    $mdDialog,
    $q,
    $scope,
    CatalogService,
    Notification,
    StatisticService,
    UserService
  ) {
    var $ctrl = this
    $ctrl.clusterCache = {}

    $scope.showGroupExport = UserService.hasRole('stats-article-group-export')

    function reset() {
      $scope.article = undefined
      $scope.query = undefined

      $scope.showOnlyOnline = false
      $scope.showStoreClusters = false

      $scope.catalogGroups = []

      $ctrl.statistic = undefined
      $ctrl.statisticCluster = undefined
      $scope.statistic = undefined

      $scope.qtyProp = 'qty'
      $scope.totalProp = 'total'

      $scope.sort.reset()
    }

    function matchArticle() {
      if (!$scope.article) {
        return
      }

      var params = {
        customerId: $scope.filter.customerId,
        from: $filter('date')($scope.filter.dates.from),
        to: $filter('date')($scope.filter.dates.to),
        q: $scope.article.id,
      }
      StatisticService.matchArticle(params).then(function (response) {
        if (response.same) {
          return
        }
        if (response.result === null) {
          Notification.info({
            title: $scope.article.name,
            message:
              '<p>Der gewählte Artikel wurde nicht im gewünschten Zeitraum gefunden.</p>' +
              '<p>Die Auswahl wurde geleert.</p>',
          })
          $scope.article = undefined
          return
        }
        $scope.article = response.result
      })
    }

    function createStatistic(data) {
      $scope.showClusterSwitch = $scope.filter.customer.options.statisticClusterBoundaries

      var statistic = {
        overview: [],
        sections: [],
      }

      data.forEach(function (row, index) {
        statistic.overview.push(getOverviewPart(row))
        statistic.sections.push(getSectionPart(row))
        $scope.sort.add(index)
      })

      if (statistic.overview.length >= 2) {
        statistic.overview.push(getOverviewSum(data))
        addPercentages(statistic.overview)
      }

      $ctrl.statistic = statistic
      updateView()
    }

    function createClusterStatistic(data, clusters) {
      var statistic = {
        overview: [],
        sections: [],
      }

      var overviewSum = {
        qty: 0,
        total: 0,
        qtyOnline: 0,
        totalOnline: 0,
      }

      clusters.forEach(function (cluster, index) {
        var section = {
          name: 'Monatlicher Umsatz: ' + cluster.name,
          columns: [],
          rows: [],
          sum: {
            qty: 0,
            total: 0,
            qtyOnline: 0,
            totalOnline: 0,
          },
        }

        var storeRowIndexes = {}

        data.sections.forEach(function (sec, systemIndex) {
          var column = {
            id: sec.system,
            name: sec.system,
            sum: {
              qty: 0,
              total: 0,
              qtyOnline: 0,
              totalOnline: 0,
            },
          }

          sec.rows.forEach(function (row) {
            if (!cluster.stores.includes(row.store.id)) {
              return
            }

            if (!Object.hasOwn(storeRowIndexes, row.store.id)) {
              var emptyCells = new Array(data.sections.length)
              for (var i = 0; i < data.sections.length; emptyCells[i++] = {}); // fill cells with empty object (fastest)
              storeRowIndexes[row.store.id] =
                section.rows.push({
                  store: row.store,
                  cells: emptyCells,
                  sum: {
                    qty: 0,
                    total: 0,
                    qtyOnline: 0,
                    totalOnline: 0,
                  },
                }) - 1
            }
            var storeRowIndex = storeRowIndexes[row.store.id]

            section.rows[storeRowIndex].cells[systemIndex] = row.sum

            // row sum
            section.rows[storeRowIndex].sum.qty += row.sum.qty
            section.rows[storeRowIndex].sum.total += row.sum.total
            section.rows[storeRowIndex].sum.qtyOnline += row.sum.qtyOnline
            section.rows[storeRowIndex].sum.totalOnline += row.sum.totalOnline

            // column sum
            column.sum.qty += row.sum.qty
            column.sum.total += row.sum.total
            column.sum.qtyOnline += row.sum.qtyOnline
            column.sum.totalOnline += row.sum.totalOnline

            // section sum
            section.sum.qty += row.sum.qty
            section.sum.total += row.sum.total
            section.sum.qtyOnline += row.sum.qtyOnline
            section.sum.totalOnline += row.sum.totalOnline

            // total sum
            overviewSum.qty += row.sum.qty
            overviewSum.total += row.sum.total
            overviewSum.qtyOnline += row.sum.qtyOnline
            overviewSum.totalOnline += row.sum.totalOnline
          })

          section.columns.push(column)
        })

        section.colWidth = calcColumnWidthPercentage(section.columns.length)

        statistic.overview.push(getOverviewPart({ name: cluster.name, sum: section.sum, stores: section.rows }))
        statistic.sections.push(section)
        $scope.sort.add(index)
      })

      if (statistic.overview.length >= 2) {
        var totalStoreCount = $filter('sum')(statistic.sections, 'rows.length')
        overviewSum.name = 'Summe'
        overviewSum.mean = {
          qty: overviewSum.qty / totalStoreCount,
          total: overviewSum.total / totalStoreCount,
          qtyOnline: overviewSum.qtyOnline / totalStoreCount,
          totalOnline: overviewSum.totalOnline / totalStoreCount,
        }
        statistic.overview.push(overviewSum)
        addPercentages(statistic.overview)
      }

      return statistic
    }

    function getOverviewPart(data) {
      return {
        name: data.name || data.system.name,
        qty: data.sum.qty,
        total: data.sum.total,
        qtyOnline: data.sum.qtyOnline,
        totalOnline: data.sum.totalOnline,
        mean: {
          qty: data.sum.qty / data.stores.length,
          total: data.sum.total / data.stores.length,
          qtyOnline: data.sum.qtyOnline / data.stores.length,
          totalOnline: data.sum.totalOnline / data.stores.length,
        },
      }
    }

    function getOverviewSum(data) {
      var sum = {
        name: 'Summe',
        sum: {
          qty: data.reduce(function (accumulator, row) {
            return accumulator + row.sum.qty
          }, 0),
          total: data.reduce(function (accumulator, row) {
            return accumulator + row.sum.total
          }, 0),
          qtyOnline: data.reduce(function (accumulator, row) {
            return accumulator + row.sum.qtyOnline
          }, 0),
          totalOnline: data.reduce(function (accumulator, row) {
            return accumulator + row.sum.totalOnline
          }, 0),
        },
        stores: data.reduce(function (accumulator, row) {
          row.stores.forEach(function (store) {
            if (!accumulator.includes(store.data.id)) {
              accumulator.push(store.data.id)
            }
          })
          return accumulator
        }, []),
      }
      return getOverviewPart(sum)
    }

    function getSectionPart(data) {
      return {
        system: data.system.name,
        name: 'Artikel bestellt über ' + data.system.name,
        columns: data.items,
        rows: data.stores.map(function (store) {
          return {
            store: store.data,
            cells: store.items,
            sum: store.sum,
          }
        }),
        sum: data.sum,
        colWidth: calcColumnWidthPercentage(data.items.length),
      }
    }

    function addPercentages(data) {
      data.forEach(function (entry, index) {
        // last index is sum
        if (index === data.length - 1) {
          return
        }
        entry.percent = {
          qty: entry.qty / data[data.length - 1].qty,
          total: entry.total / data[data.length - 1].total,
          qtyOnline: entry.qtyOnline / data[data.length - 1].qtyOnline,
          totalOnline: entry.totalOnline / data[data.length - 1].totalOnline,
        }
      })
    }

    function calcColumnWidthPercentage(columnCount) {
      return 100 / (1 + columnCount + (columnCount > 1)) / 2 + '%'
      //            ^ store            ^ sum
    }

    function updateView() {
      if (!$scope.showStoreClusters) {
        $scope.statistic = $ctrl.statistic
        return
      }

      var promise
      var params = {
        customerId: $scope.filter.customerId,
        date: $filter('date')($scope.filter.dates.to, '01.MM.y'),
      }
      if ($ctrl.clusterCache[JSON.stringify(params)]) {
        promise = $q.resolve($ctrl.clusterCache[JSON.stringify(params)])
      } else {
        $scope.loading = true
        promise = StatisticService.groupStoresByMonthlySales(params)
          .then(function (response) {
            $ctrl.clusterCache[JSON.stringify(params)] = response
            return response
          })
          .finally(function () {
            $scope.loading = false
          })
      }

      promise.then(function (clusters) {
        $scope.statistic = createClusterStatistic($ctrl.statistic, clusters)
      })
    }

    $scope.articleQuerySearch = function (query) {
      var params = {
        customerId: $scope.filter.customerId,
        from: $filter('date')($scope.filter.dates.from),
        to: $filter('date')($scope.filter.dates.to),
        q: query,
      }
      return StatisticService.getArticlesAutocomplete(params)
    }

    $scope.sort = {
      reset: function () {
        this.property = 'store'
        this.reverse = false
        this.predicates = []
        this.predicateProp = 'global'
      },
      resetPredicates: function () {
        if (!['store', 'sum'].includes(this.property)) {
          this.property = 'store'
          this.reverse = false
          this.predicates.forEach(function (predicates) {
            predicates.global = predicates.global.slice(-2)
            predicates.online = predicates.online.slice(-2)
          })
        }
      },
      add: function (index) {
        if (Object.hasOwn(this.predicates, index)) {
          return
        }
        if (this.predicates[0]) {
          this.predicates[index] = angular.copy(this.predicates[0])
          return
        }
        this.predicates[index] = {
          global: ['store.foreignIdent', 'store.name'],
          online: ['store.foreignIdent', 'store.name'],
        }
      },
      by: function (property) {
        var sort = this

        // order by current property (change direction)
        if (sort.property === property) {
          sort.reverse = !sort.reverse
          return
        }

        sort.predicates.forEach(function (predicates, index) {
          // remove old predicates
          predicates.global = predicates.global.slice(-2)
          predicates.online = predicates.online.slice(-2)

          if (property === 'store') {
            return
          }

          if (property === 'sum') {
            predicates.global = ['sum.qty', 'sum.total'].concat(predicates.global)
            predicates.online = ['sum.qtyOnline', 'sum.totalOnline'].concat(predicates.online)
            return
          }

          if ($scope.statistic.sections[index] == null) {
            return
          }
          var colIdx = $scope.statistic.sections[index].columns.findIndex(function (col) {
            return col.id === property
          })
          if (colIdx > -1) {
            predicates.global = ['cells[' + colIdx + '].qty', 'cells[' + colIdx + '].total'].concat(predicates.global)
            predicates.online = ['cells[' + colIdx + '].qtyOnline', 'cells[' + colIdx + '].totalOnline'].concat(
              predicates.online
            )
          }
        })

        sort.property = property
      },
    }

    $scope.loading = false
    $scope.load = function (event, exportType) {
      if ($scope.loading || !(exportType || $scope.article || $scope.query)) {
        return $q.reject()
      }

      var params = {
        customerId: $scope.filter.customerId,
        storeId: $scope.filter.storeId && $scope.filter.storeId.length ? $scope.filter.storeId : undefined,
        from: $filter('date')($scope.filter.dates.from),
        to: $filter('date')($scope.filter.dates.to),
      }

      if ($scope.article) {
        params.q = $scope.article.id
      } else {
        params.query = $scope.query
      }

      $scope.loading = true
      var promise

      if (exportType) {
        params.export = exportType
        promise = StatisticService.exportArticlesStatistic(params)
      } else {
        promise = StatisticService.getArticlesStatistic(params)
          .then(createStatistic)
          .then(function () {
            $scope.statsForMultipleStores = params.storeId == null || params.storeId.length >= 2
          })
      }

      return promise.finally(function () {
        $scope.loading = false
      })
    }

    $scope.export = function (event) {
      return $scope.load(event, true)
    }

    $scope.exportFull = function (event) {
      return $scope.load(event, 'full')
    }

    $scope.exportGroups = function (event) {
      var filter = $scope.filter
      return $mdDialog.show({
        templateUrl: 'src/statistics/views/dialog.select-group.html',
        targetEvent: event,
        controller: function ($scope, groups) {
          $scope.groups = groups

          $scope.close = function () {
            $mdDialog.cancel()
          }

          $scope.export = function () {
            var params = {
              customerId: filter.customerId,
              type: $scope.groupSku != null ? 'group' : 'groups',
              from: $filter('date')(filter.dates.from),
              to: $filter('date')(filter.dates.to),
              sku: $scope.groupSku,
            }
            $scope.loading = true
            StatisticService.exportArticlesStatistic(params)
              .then($mdDialog.hide)
              .finally(function () {
                $scope.loading = false
              })
          }
        },
        locals: {
          groups: $scope.catalogGroups,
        },
      })
    }

    $scope.exportOptions = function (event) {
      if ($scope.loading) {
        return $q.reject()
      }

      var params = {
        customerId: $scope.filter.customerId,
        storeId: $scope.filter.storeId,
        type: 'options',
        from: $filter('date')($scope.filter.dates.from),
        to: $filter('date')($scope.filter.dates.to),
      }

      $scope.loading = true
      return StatisticService.exportArticlesStatistic(params).finally(function () {
        $scope.loading = false
      })
    }

    $scope.$watch('filter.customer', function (customer) {
      reset()
      if (customer) {
        CatalogService.getGroups(customer.customerId).then(function (response) {
          $scope.catalogGroups = response
        })
      }
    })

    $scope.$watchCollection('[filter.dates.from, filter.dates.to]', function (newValues, oldValues) {
      var values = newValues.concat(oldValues).map(function (value) {
        if (value instanceof Date) {
          return moment(value).format('YYYY-MM')
        }
        return value
      })

      var newFrom = values[0]
      var newTo = values[1]
      var oldFrom = values[2]
      var oldTo = values[3]

      if (newFrom && newTo && (newFrom !== oldFrom || newTo !== oldTo)) {
        matchArticle()
      }
    })

    $scope.$watch('{ online: showOnlyOnline, cluster: showStoreClusters }', function (newValues, oldValues) {
      if (angular.equals(newValues, oldValues)) {
        return
      }
      if (newValues.online !== oldValues.online) {
        if (newValues.online) {
          $scope.qtyProp = 'qtyOnline'
          $scope.totalProp = 'totalOnline'
          $scope.sort.predicateProp = 'online'
        } else {
          $scope.qtyProp = 'qty'
          $scope.totalProp = 'total'
          $scope.sort.predicateProp = 'global'
        }
        return
      }
      $scope.sort.resetPredicates()
      updateView()
    })

    // initial reset
    reset()
  }
})()
